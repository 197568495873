<template>
  <b-card class="mb-4">
    <template #header> 
      <i class='bx bxs-cart' />
      {{$t('shipments.merchant')}}
    </template>

      <form-input 
        label="shipments.merchant" 
        icon="bx-cart"
        type="item"
        v-bind="{rules}"
        v-model="item.merchant_id"
        resource-name="merchants"
        :clearable="false"
      />
  </b-card>
</template>

<script>
import FormInput from "@/components/Form/Input";

export default {
  components: {FormInput},
  props: {
    item: {
      type: Object,
      required: true
    },
    rules: {
      type: [String,Object],
      default: 'required'
    }
  }
}
</script>