<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <v-validate-observe ref=observer v-slot="{ invalid, touched, dirty }">
          <h1>
            <i :class="['bx', resource.icon]"></i>
            {{ item.$meta.title }}
            <slot name="tags" />
            <span v-if="item.$exists && dirty" class="float-right">
              <span class="text-danger small" style="">{{$t(`item.unsaved-changes`)}}</span>
            </span>
          </h1>

          <slot />

          <b-card class="bottom-action-bar mb-2">
            <slot name="buttons" v-bind="{invalid, touched}">
              <b-button :disabled="invalid" @click="$emit('save')" variant="primary" class="float-right">
                {{ creating ? $t("item.create") : $t("item.save") }}
              </b-button>
              <span v-if="touched && invalid" class="p-2 text-danger float-right">{{$t('validation.form-invalid')}}</span>
              <b-button @click="$emit('cancel')" variant="light">
                {{ $t("item.cancel") }}
              </b-button>
            </slot>
          </b-card>
        </v-validate-observe>
      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/Main";
import Debug from "@/components/Debug";
import { 
  ValidationObserver as vValidateObserve, 
} from 'vee-validate';
import Resource from '@/services/Resource';

export default {
  components: { 
    MainLayout, 
    vValidateObserve,
    Debug,
  },
  props: {
    item: Resource,
    resource: Function,
    loading: Boolean,
    creating: Boolean,
  }
};
</script>