<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <template #tags>
      <span v-if="isOwn" class="small text-muted">Me</span>
    </template>

    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bxs-user"></i>
            {{ $t("users.label") }}
          </template>
          <form-input 
            v-if="userIsSuperAdmin && (item.isMerchant || item.isStore)"
            type="checkbox"
            label="common.active" 
            icon="bx-check-circle"
            v-model="item.active"
            switch
            size="lg"
          />
          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input
                label="common.first_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.first_name"
              />
            </div>
            <div class="col">
              <form-input
                label="common.last_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.last_name"
              />
            </div>
          </div>
          <form-input 
            label="common.email" 
            icon="bx-envelope"
            v-model="item.email"
            rules="required|email"
            :disabled="item.$exists"
            :description="creating ? $t('users.email-info') : null"
          />
          <form-input 
            v-if="!item.isMerchant"
            type="country"
            icon="bx-globe"
            :rules="{required: itemIsDriver}"
            label="common.country"
            v-model="item.country"
          />
        </b-card>

        <b-card v-if="userIsSuperAdmin || userIsMerchantAdmin">
          <template #header>
            <i class="bx bxs-user-detail"></i>
            {{ $t("users.role") }}
          </template>
          <form-input 
            bitmask
            type="select"
            label="users.role" 
            icon="bxs-user-detail"
            v-model="item.role"
            :rules="required"
            :clearable="false"
            :options="roleOptions"
          />
        </b-card>

      </div>
      <div class="col mb-4">
        <b-card v-if="isOwn || userIsSuperAdmin">
          <template #header>
            <i class="bx bx-cog"></i>
            {{ $t("users.preferences.label") }}
          </template>
          <form-input 
            type="select"
            label="users.preferences.locale" 
            icon="bx-message-square-detail"
            v-model="item.locale"
            rules="required"
            :options="resource.locales | transMap"
            :clearable="false"
          />
          <form-input
            v-if="item.isMerchant"
            type="item" 
            label="users.preferences.default-pickup-location" 
            icon="bx-map" 
            v-model="item.preferences.default_pickup"
            resource-name="locations"
            :scope="['pickup','active','approved',['merchant', item.merchant_id]]"
            clearable
          />
          <form-input
            type="select" 
            :options="exportFormats"
            label="users.preferences.default-export-format" 
            icon="bxs-file-export" 
            v-model="item.preferences.default_export_format"
            clearable
          />
          <form-input 
            v-if="userIsSuperAdmin && !item.isMerchant"
            type="select"
            bitmask
            label="merchants.features" 
            icon="bx bx-hive"
            :options="allFeatures | transMap"
            v-model="item.preferences.features"
          />
        </b-card>

        <b-card v-if="isOwn">
          <template #header>
            <i class="bx bx-key"></i>
            {{ $t("users.password") }}
          </template>

          <template v-if="passwordResetSent">
            <b-alert show variant="info">{{$t('users.change-password-sent')}}</b-alert>
          </template>
          <template v-else>
            <p>
              {{ $t('users.change-password-info')}}
            </p>
            <div class="text-center">
              <b-button @click="resetPassword" variant="primary">
                {{ $t("users.change-password-button") }}
              </b-button>
            </div>
          </template>
        </b-card>

        <merchant-card v-if="userIsSuperAdmin && item.isMerchant" :item="item" />

        <b-card v-if="userIsSuperAdmin && item.isStore && !item.isSuperAdmin">
          <template #header>
            <i class="bx bxs-store"></i>
            {{ $t("users.pudo-warehouse") }}
          </template>

          <form-input 
            type="item"
            multiple
            label="users.locations" 
            icon="bx bx-map"
            resource-name="locations"
            :refreshOn="item.role"
            :scope="['dropoff','active',['class',locationClasses]]"
            v-model="item.locations"
          />
        </b-card>
      </div>
    </div>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import ItemEditTemplate from "@/components/ItemEditTemplate";
import FormInput from "@/components/Form/Input";
import MerchantCard from "@/components/Form/MerchantCard";
import { roleMap, ROLE_CUSTOMER_SUPPORT, ROLE_DRIVER, ROLE_MERCHANT_ADMIN, ROLE_MERCHANT_USER, ROLE_PUDO, ROLE_SUPERADMIN, ROLE_WAREHOUSE } from '@/resources/User';
import { mapActions, mapGetters } from 'vuex';
import { exportFormats } from '@/resources/Shipment'
import Merchant from '@/resources/Merchant';

export default {
  extends: ItemEdit,
  components: { ItemEditTemplate, FormInput, MerchantCard },
  data() {
    return {
      isOwn: false,
      passwordResetSent: false,
      env: process.env,
      exportFormats,
      allFeatures: Merchant.features
    }
  },
  created() {
    if(this.user.id == this.$route.params.id) {
      this.isOwn = true
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      userRole: 'auth/role',
      userIsSuperAdmin: 'auth/isSuperAdmin',
      userIsMerchantAdmin: 'auth/isMerchantAdmin',
    }),
    roleOptions() {
      let superadmin = {label: this.$t(roleMap[ROLE_SUPERADMIN]), code: ROLE_SUPERADMIN}
      let supportUser = {label: this.$t(roleMap[ROLE_CUSTOMER_SUPPORT]), code: ROLE_CUSTOMER_SUPPORT}
      let merchantAdmin = {label: this.$t(roleMap[ROLE_MERCHANT_ADMIN]), code: ROLE_MERCHANT_ADMIN}
      let merchantUser = {label: this.$t(roleMap[ROLE_MERCHANT_USER]), code: ROLE_MERCHANT_USER}
      let pudoUser = {label: this.$t(roleMap[ROLE_PUDO]), code: ROLE_PUDO}
      let warehouseUser = {label: this.$t(roleMap[ROLE_WAREHOUSE]), code: ROLE_WAREHOUSE}
      let driverUser = {label: this.$t(roleMap[ROLE_DRIVER]), code: ROLE_DRIVER}

      if(this.userRole & ROLE_SUPERADMIN)
        return [superadmin,supportUser,merchantAdmin,merchantUser,pudoUser,driverUser,warehouseUser]
      else if(this.userRole & ROLE_CUSTOMER_SUPPORT)
        return [supportUser,merchantAdmin,merchantUser,pudoUser,driverUser,warehouseUser]
      else if(this.userRole & ROLE_MERCHANT_ADMIN)
        return [merchantAdmin,merchantUser]
      else
        return []
    },
    itemIsDriver() {
      return !!(this.item.role & ROLE_DRIVER)
    },
    locationClasses() {
      const classes = []

      if(this.item.role & ROLE_PUDO) classes.push('pudo')
      if(this.item.role & ROLE_WAREHOUSE) classes.push('warehouse')

      return classes
    },
  },
  methods: {
    ...mapActions({
      signOut: 'auth/signOut',
      setUser: 'auth/setUser',
      forgotPassword: 'auth/forgotPassword',
    }),
    async resetPassword() {
      const data = new FormData();
      data.append("email", this.user.email);
      try {
        await this.signOut()
        await this.forgotPassword(data);
        this.passwordResetSent = true
      } catch (error) {
        console.log({error})
      }
    },
    afterSave(data) {
      if(this.isOwn) {
        this.setUser(data)
      }

      if(this.isOwn || this.creating) {
        this.$router.replace({name: `${this.resource.plural}-show`,params:{id: this.item.id}})
      } else {
        this.$router.go(-1)
      }
    }
  }
};
</script>